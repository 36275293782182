.AppHeader {
	display: inline-block;
	width: 100%;
	background: rgb(239 245 245);
	position: sticky;
	top: 0;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 10px;
	height: auto;
	z-index: 1000;
	padding-left: 20px;
}
