.SideBar {
	background-color: var(--appMainColor);
	position: sticky;
	top: 0;
	left: 0;
	overflow: hidden;
	width: 180px;
	min-width: 180px;
	margin: 0px;
	padding: 0px;
}

.SideBarContent {
	position: fixed;
	width: inherit;
	background-color: var(--appMainColor);
}

.LinkContainer {
	display: table;
	vertical-align: middle;
	width: 100%;
}

.Logo {
	padding-top: 10px;
	padding-left: 10px;
	padding-bottom: 10px;
	padding-right: 10px;
	width: 100%;
}

.LogoMini {
	margin: 0px 5px 0px 5px;
	max-width: 70px;
	padding-top: 5px;
	padding-left: 10px;
	padding-bottom: 5px;
	padding-right: 10px;
}

@media (min-width: 1001px) and (min-height: 767px) {
	.SideBar {
		display: inline !important;
	}
}

@media (max-width: 1000px), (max-height: 767px) {
	.SideBar {
		min-width: 80px;
		width: 80px;
	}
	.LinkName {
		display: none;
	}
}

@media (max-height: 524px) {
	.SideBar {
		min-width: 40px;
		width: 40px;
	}
}
