@font-face {
	font-family: SegoeUI;
	src: local("Segoe UI Light"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2) format("woff2"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff) format("woff"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf) format("truetype");
	font-weight: 100;
}

@font-face {
	font-family: SegoeUI;
	src: local("Segoe UI Semilight"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2) format("woff2"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff) format("woff"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf) format("truetype");
	font-weight: 200;
}

@font-face {
	font-family: SegoeUI;
	src: local("Segoe UI"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2) format("woff2"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff) format("woff"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf) format("truetype");
	font-weight: 400;
}

@font-face {
	font-family: SegoeUI;
	src: local("Segoe UI Semibold"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2) format("woff2"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff) format("woff"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf) format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: SegoeUI;
	src: local("Segoe UI Bold"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2) format("woff2"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff) format("woff"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf) format("truetype");
	font-weight: 700;
}

.App {
	display: inline-flex;
	justify-content: space-between;
	width: 100%;
	font-family: "SegoeUI" !important;
}

.AppIcon {
	font-size: 20px;
}
.AppIcon:hover {
	color: var(--appMainColor);
}

.AppContainer {
	width: 100%;
}

/* SideBar */
.SideBarLink {
	color: rgb(0, 0, 0);
	text-decoration: none;
	text-align: center;
	width: 100%;
	padding: 5px;
	border: 5px solid transparent;
	justify-content: center;
	height: 100px;
	display: table-cell;
	vertical-align: middle;
}

.SideBarLink:hover,
.ActiveSideBarLink:hover {
	border: 5px solid;
	background: var(--appMainColor);
	border-left-color: #000000;
	color: #000000;
	text-decoration: none;
	border-bottom: none;
	border-top: none;
	border-right: none;
}

.ActiveSideBarLink {
	border: 5px solid;
	background: #eff5f5 !important;
	border-left-color: #000000;
	color: #000000;
	text-decoration: none;
	border-bottom: none;
	border-top: none;
	border-right: none;
	text-align: center;
	width: 100%;
	padding: 5px;
	justify-content: center;
	height: 100px;
	display: table-cell;
	vertical-align: middle;
}

a {
	text-decoration: none !important;
}

.textCenter {
	text-align: center;
}

.textRight {
	text-align: right;
}

.fieldErrorMessage {
	color: #dc3545;
	font-size: 14px;
}

.marginTop32 {
	margin-top: 32px !important;
}

form {
	label {
		margin-top: 10px;
	}

	input[type="radio"],
	input[type="Checkbox"] {
		margin-right: 5px;
	}
}

@media (min-width: 992px) {
	button.translateBottomLg {
		transform: translateY(calc(100% - 6px));
	}

	a.translateBottomLg {
		display: inline-flex;
		width: fit-content;
		transform: translateY(calc(100% + 15px));
	}

	.row.reversed {
		flex-direction: row-reverse;
	}
}

/* Media for responsive side nav bar */
@media (max-width: 1000px), (max-height: 767px) {
	.SideBarLink,
	.SideBarLink:hover,
	.ActiveSideBarLink,
	.ActiveSideBarLink:hover {
		height: 50px;
	}
}
@media (max-height: 524px) {
	.SideBarLink,
	.SideBarLink:hover,
	.ActiveSideBarLink,
	.ActiveSideBarLink:hover {
		height: 40px;
	}
}
